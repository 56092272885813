const layout = 'admin';
const roles = ['admin'];

const admin = [
  {
    path: '/admin/announcements',
    name: 'admin.announcements',
    component: () => import('@/views/admin/announcements/Announcements.vue'),
    meta: {
      layout,
      roles
    },
  },

  {
    path: '/admin/announcements/:id',
    name: 'admin.announcements.show',
    component: () => import('@/views/admin/announcements/AnnouncementsShow.vue'),
    meta: {
      layout,
      roles
    },
  },

  {
    path: '/admin/projects/:id',
    name: 'admin.projects.show',
    component: () => import('@/views/admin/projects/ProjectShow.vue'),
    meta: {
      layout,
      roles
    },
  },

  {
    path: '/admin/templates',
    name: 'admin.templates',
    component: () => import('@/views/admin/templates/TemplatesView.vue'),
    meta: {
      layout,
      roles
    },
  },

  {
    path: '/admin/users',
    name: 'admin.users',
    component: () => import('@/views/admin/users/UsersView.vue'),
    meta: {
      layout,
      roles
    },
  },

  {
    path: '/admin/announcements/:id/tasks',
    name: 'admin.announcements.tasks',
    component: () => import('@/views/admin/tasks/TasksView.vue'),
    meta: {
      layout,
      roles
    },
  },
]

export default admin
