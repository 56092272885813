import { LINK_2023_FR_DOWNLOAD_AVAL_INSTITUTIONAL } from "@/assets/util";

export default {
  Spanish: "Espagnol",
  French: "Français",
  Announcement: "APPEL A CANDIDATURES",
  AnnouncementAll: "Voir les appels précédents",
  AnnouncementCurrent: "Appel en CANDIDATURES",
  AddAnnouncement: "CREER APPEL A CANDIDATURES",
  EditAnnouncement: "MODIFIER APPEL A CANDIDATURES",
  "My Account": "Mon compte",
  Logout: "Sortir",
  Project: "Projet",
  "See Project": "JE SAISIS MON PROJET",
  "Create Project": "Créer un projet",
  "Start Register": "JE SAISIS MON PROJET",
  "Title Project": "Titre du projet",
  "Description Project": "Description du projet",
  "Date Project": "Date du projet",
  "Status Project": "Statut du proyecto",
  "Data Project":
    "Programme de coopération ECOS Sud-Mincyt  Argentine - Fiche-projet",
  "Permissions Project": "Partager le projet",
  "Permissions Give": "Partager",
  "Permissions Project info":
    "Entrer ici l’adresse électronique avec laquelle le directeur de votre contrepartie s’est inscrit afin de relier les présentations des deux pays",
  Send: "Envoyer",
  Close: "Près de",
  Change: "Modifier",
  Next: "Suivant",
  Back: "Arrière",
  Signin: "S'identifier",
  Email: "E-mail",
  Password: "Mot de passe",
  RePassword: "Re mot de passe",
  FirstName: "Prénom",
  LastName: "Nom de famille",
  Country: "De campagne",
  Start: "S'identifier",
  GetItBack: "Récupérer",
  ForgotPass: "Mot de passe oublié",
  Signup: "S'inscrire",
  AskForgotPass: "Vous n'avez pas encore de compte?",
  AskSignin: "Avez-vous déjà un compte?",
  Register: "S'inscrire",
  accept: "J'accepte",
  acceptTerms: "termes et politiques de confidentialité",
  SignupSuccess: "Enregistrement de compte réussi !",
  SignupError: "L'adresse électronique saisie existe déjà",
  SigninError: "¡Ups! Vos identifiants ne sont pas corrects",
  "Registration Closing": " Date de clôture de l'Appel à projets",
  "url-accept":
    "https://drive.google.com/file/d/1xq8R0dFNUYxfRIRscQwHAFODqBeGjGZL/view?usp=sharing",
  "Name Permission": "NOM",
  "Email Permission": "ADRESSE MAIL",
  ForgotPassError:
    "¡Ups! impossible de terminer la récupération du mot de passe",
  ForgotPassSuccess: "Mot de passe mis à jour avec succès",
  ForgotPassSendMail:
    "Nous avons envoyé un e-mail pour terminer la récupération du mot de passe",

  // Status project
  draft: "Envoyer",
  pending: "En attente d'évaluation",
  reviewed: "Évalué",
  approved: "Accepté",
  rejected: "Rejeté",
  pendingOnly: "En attente",

  "Mail user": "Adresse mail de la contrepartie",

  Info: "Information",
  MessageSuccess: "Action effectuée avec succès",
  MessageError: "Quelque chose d'inattendu s'est produit, veuillez réessayer",
  FilesUpload: "Fichiers téléchargés",
  "Process complete title": "Étapes à suivre :",
  "Process complete": `1. Le/la directeur/trice argentin/français s'inscrit et crée le nouveau projet.<br><br>
  2. L'homologue s'inscrit également et communique au directeur qui a créé le projet l'adresse e-mail avec laquelle il s'est inscrit.<br><br>
  3. Le/a directeur/trice qui a créé le projet partage l'accès à son homologue en cliquant sur le bouton PARTAGER LE PROJET. <br><br>
  4. A partir de ce moment, le projet est correctement partagé.<br><br>
  5. Chaque directeur/trice saisira le projet de son côté et pourra voir en parallèle le téléchargement de son homologue de l'autre pays.<br><br>
  6. Le projet sera définitivement soumis à évaluation lorsque les deux parties argentine et française auront téléchargé toutes les informations avant le 15 avril 2023, et pour les avals institutionnels avant le 30 avril 2023.<br><br>
  7. Les directeurs/trices argentins et français pourront saisir leurs projets sur la plateforme à tout moment de l'appel à projets pour compléter ou corriger les informations.
  <br><br>`,
  "annuncement text":
    "L'appel 2022 du programme Ecos Sud Argentine est ouvert jusqu'au 15 avril 2023.<br>Nous avons le plaisir de vous informer que désormais les projets seront soumis exclusivement via cette plateforme dématérialisée, tant par les équipes argentines que françaises, afin de faciliter les candidatures conjointes.<br>L'équipe ECOS-SUD ARGENTINE espère que cette nouvelle modalité répondra à vos attentes et se tient à votre disposition pour toute question.",

  Error: "Une erreur imprévue s'est produite",
  SharedProject: {
    SUCCESS: "Votre projet a été partagé avec succès avec: ",
    ANNOUNCEMENT_DISABLED: "Actuellement, l'appel est inactif",
    EXIST_USER_DIRECTOR: "Vous ne pouvez inviter qu'un seul utilisateur en tant que directeur à ce projet",
    USER_COUNTRY_EQUAL: "Vous ne pouvez inviter qu'un utilisateur en tant d'un pays autre que le vôtre",
    USER_NOT_FOUND:
      " Il n'y a pas d'utilisateur enregistré sur la plateforme avec cette adresse e-mail.",
    USER_IS_SHARED: `
      <p>Vous avez déjà partagé votre projet avec cet utilisateur. Si la personne à qui vous avez partagé le projet ne peut pas voir vos modifications, cela signifie que vous avez tous les deux commencé le processus d'enregistrement du projet indépendamment.  </p>
      <br />
      <p><strong>Solution: </strong>  L'un de vous doit créer un nouveau compte dans le système avec une adresse électronique différente et demander à votre partenaire de partager le projet à cette nouvelle adresse.</p>
    `,
    USER_IS_OWNER: `
      <p>Une erreur s'est produite.  L'utilisateur à qui vous essayez de partager le projet a déjà lancé la création d'un projet. Un seul utilisateur peut lancer le processus d'enregistrement d’un projet.   </p>
      <br />
      <p><strong>Solution: </strong>  L'un des deux partenaires doit créer un nouveau compte dans le système avec une adresse électronique différente et demander à son partenaire de partager le projet à cette nouvelle adresse.</p>
    `,
    EXIST_PROJECT_INVITE: "Vous avez déjà une invitation en attente, si vous le souhaitez, vous pouvez l'annuler et la renvoyer.",
    STEP_ONE: `
      <p>Le projet n'a PAS encore été partagé. Veuillez saisir l'adresse électronique de votre partenaire argentin et cliquer sur le bouton "PARTAGER".</p>
      <p>Le système enverra l'invitation par e-mail à collaborer au projet.</p>
      <p>Une fois l'invitation acceptée, le projet sera partagé avec succès.</p>
    `,
    STEP_TWO: `
      <p>Le système a envoyé l'invitation par e-mail à collaborer au projet.</p>
      <p>Une fois l'invitation acceptée, le projet sera partagé avec succès.</p>
      <p>Si l'invitation n'a PAS encore été acceptée par le partenaire, vous pouvez l'annuler en cliquant sur  <strong>"Annuler l'invitation"</strong>.</p>
    `,
    PROJECT_SHARED_SUCCESSFULLY: `
      <p>Le projet est correctement partagé.</p>
    `,
    SOLICITUDE_PENDING: "Candidature en attente d'acceptation",
  },
  SaveProject: "Sauvegarder le projet",
  Managers: "Managers",
  Discipline: "Discipline",
  Status: "Statut",
  Search: "Recherche",
  Owner: "Créateur de projet",
  VersionEs: "Version sspagnol",
  VersionFr: "Version français",
  ProjectFiles: "Dossiers de projets",
  SearchListProject: "Recherche par projet ou par créateur",

  // Categories proyect
  ch2_1_1: "Sciences de la Santé",
  ch2_1_2: "Sciences Humaines y Sociales",
  ch2_1_3: "Sciences de la Vie",
  ch2_1_4: "Sciences Exactes",
  ch2_1_5: "Sciences de l’Univers",

  Download: "Télécharger",
  Details: "Détails",
  UpdateProject: "Projet de mise à jour",
  Enable: "Activer",
  Disable: "Désactiver",
  "Templates manager": "Gestion des Modèles",
  "Edit template": "Modifier modèles",
  Templates: "Modèles",
  "Create new template": "Créer un nouveau modèle",
  "Assign selected projects": "Affecter les projets sélectionnés",
  "Save template": "Sauvez Modèles",
  "Select area": "Sélectionnez la zone",
  "Task title": "Titre de la tâche",
  "Task description": "Description de la tâche",
  "Assign in block": "Affecter en bloc",
  "Select assign taks":
    "Sélectionnez les projets auxquels vous souhaitez affecter les tâches sélectionnées",
  Users: "Utilisateurs",
  "Users list": "Liste des utilisateurs",
  "Add user": "Ajouter un utilisateur",
  Role: "Rôle",
  User: "Utilisateur",
  Areas: "Secteurs",
  State: "État",
  "Full name": "Nom et prénom",
  "Invite send": "Envoyer une invitation",
  "Edit user": "Modifier utilisateur",
  Phone: "Téléphone",
  Position: "Position",
  Save: "Sauvez",
  "Delete user": "Supprimer l'utilisateur",
  "Areas list": "Liste des zones",
  Tasks: "Tâches",
  Progress: "Progrès",
  Director: "Directeur",
  "Add task": "Ajouter tâches",
  "Select role": "Sélectionnez rôle",
  Active: "Active",
  Inactive: "Inactive",

  // Roles
  admin: "Administrateur",
  "project-manager": "Candidat",
  "director": "Directeur",
  "coordinator": "Coordinateur",
  "approver": "Approbateur",
  "validator": "Validateur",

  approvers: "Approbateurs",
  validators: "Validateurs",
  collaborator: "Collaborateur",

  // Messages big
  MESSAGE_TEMPLATE_ASSIGN:
    "N'oubliez pas que vous devrez ensuite désigner des approbateurs et des validateurs pour ces tâches. Si vous ne le faites pas, l'approbation est laissée aux coordinateurs de chaque projet.",
  MESSAGE_NEW_USER:
    "Saisissez les coordonnées du nouvel utilisateur et l'invitation sera envoyée à l'adresse électronique fournie. ",
  MESSAGE_ADD_USER_TO_TASKS: "Vous pouvez allouer dans cette étape ou sinon plus tard",
  MESSAGE_IMCOMPLETE_SAVE_PROYECT: "Le projet NE PEUT PAS être soumis car il contient encore des champs incomplets.",
  MESSAGE_NOT_UPLOAD_AVAL_PROYECT: `
    <p>Si vous n'avez pas téléchargé le modèle « Approbation institutionnelle », vous pouvez le télécharger en cliquant sur <a href='${LINK_2023_FR_DOWNLOAD_AVAL_INSTITUTIONAL}' target='_blank'>ici</a></p>
    <p style='color: #ff4c51;'>N'oubliez pas que le projet n'est PAS terminé tant que la garantie institutionnelle n'est pas téléchargée. La date limite pour le télécharger est le 30 avril 2023</p>
  `,

  "Select project": "Sélectionnez projets",
  "Only create": "Créer une tâche uniquement",
  "Create and template task": "Créer une tâche et l'enregistrer comme modèle",
  "New task": "Nouvelle tâche",
  "Empty information": "No information to show",
  "See tasks": "Gestion les tâches",
  "See projects": "Voir les projets",
  "Project managers" : "Chefs de projet ",
  "Creator": "Créateur",
  "Project shared": "Projet partagé",
  "Shared": "Partagé",
  "Not assign": "Pas encore attribué",
  "Cancele invitation": "Cancele invitation",
  Upload: "Porter",
  See: "Regarder",
  Aval: "Aval institutionnel",
  'Information project': 'Information sur le projet',

  // Tasks status
  'progress': 'À Progreso',
  'pending_approval': 'Pendiente de Aprobación',
  'approval_rejected': `En attente d'approbation`,
  'pending_validation': `En attente de validation`,
  'validation_rejected': 'Validation refusée',
  'completed': 'Complété',
};
