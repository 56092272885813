export default {
  namespaced: true,
  state: {
    projectSelect: null,
    selectProjectTasks: [],
  },
  getters: {
    getProjectSelect: ({ projectSelect }) => {
      return projectSelect;
    },
    getSelectProjectTasks: ({ selectProjectTasks }) => {
      return [...selectProjectTasks];
    },
  },
  mutations: {
    SET_PROJECT_SELECT(state, value) {
      state.projectSelect = value;
    },
    SET_SELECT_PROJECT_TASKS(state, value) {
      state.selectProjectTasks = value;
    },
  },
  actions: {
    onSetSelectProjectTasks({ commit, state }, task) {
      const tasks = [task, ...state.selectProjectTasks];

      commit("SET_SELECT_PROJECT_TASKS", tasks);
    },
  },
};
