export default {
  namespaced: true,
  state: {
    hasNewTemplate: null,
    assignTemplates: [],
  },
  getters: {
    assignTemplateId(state) {
      return state.assignTemplates.map((item) => item.id);
    },
  },
  mutations: {
    SET_NEW_TEMPLATE(state, value) {
      state.hasNewTemplate = value;
    },
    SET_ASSIGN_TEMPLATES(state, value) {
      state.assignTemplates = [...value];
    },
  },
  actions: {
    onItemAssignTemplates({ commit, state }, data) {
      const { value, item } = data;
      const allAssignTemplates = state.assignTemplates;
      const projects = allAssignTemplates.filter((p) => p.id !== item.id);

      commit(
        "SET_ASSIGN_TEMPLATES",
        value ? [...allAssignTemplates, item] : projects
      );
    },
    onAllAssignTemplates({ commit }, data) {
      const { value, items } = data;
      commit("SET_ASSIGN_TEMPLATES", value ? items : []);
    },
  },
};
