import { LINK_2023_ES_DOWNLOAD_AVAL_INSTITUTIONAL } from "@/assets/util";


export default {
  Spanish: "Castellano",
  French: "Francés",
  Announcement: "Convocatorias",
  AnnouncementAll: "Ver convocatorias anteriores",
  AnnouncementCurrent: "Convocatoria actual",
  AddAnnouncement: "Nueva Convocatoria",
  EditAnnouncement: "Editar Convocatoria",
  "My Account": "Mi Cuenta",
  Logout: "Salir",
  Project: "Proyectos",
  "See Project": "Ver Proyecto",
  "Create Project": "Registrar Proyecto",
  "Start Register": "Comenzar Inscripción",
  "Title Project": "Titulo del proyecto",
  "Description Project": "Descripción del proyecto",
  "Date Project": "Fecha del proyecto",
  "Status Project": "Estatus del proyecto",
  "Data Project":
    "Programa de cooperación ECOS Sud-MinCyT (Argentina) | Ficha - Proyecto",
  "Permissions Project": "Compartir proyecto",
  "Permissions Project info":
    "Es necesario ingresar aquí el correo electrónico con el que el Director de la contraparte se registró/registrará a fin de vincular las presentaciones en ambos países",
  "Permissions Give": "Compartir",
  Send: "Enviar",
  Close: "Cerrar",
  Change: "Modificar",
  Next: "Siguiente",
  Back: "Anterior",
  Signin: "Iniciar sesión",
  Email: "Correo electrónico",
  Password: "Contraseña",
  RePassword: "Repetir Contraseña",
  FirstName: "Nombre",
  LastName: "Apellido",
  Country: "País",
  Start: "Iniciar",
  GetItBack: "Recuperar",
  ForgotPass: "Recuperar contraseña",
  Signup: "Crear una cuenta",
  AskForgotPass: "¿Aún no tienes una cuenta?",
  AskSignin: "¿Ya tienes una cuenta?",
  Register: "Registrar",
  accept: "Acepto",
  acceptTerms: "Términos y políticas de privacidad",
  SignupSuccess: "¡Registro de cuenta exitoso!",
  SignupError: "El correo electrónico ingresado ya existe",
  SigninError: "¡Ups! tus credenciales no son correctas",
  "Registration Closing": " Fecha de cierre de inscripciones",
  "url-accept":
    "https://drive.google.com/file/d/1yjeOs7ty750w7IBteOK-Ra17yloXJwEb/view?usp=sharing",
  "Name Permission": "NOMBRE",
  "Email Permission": "EMAIL",
  ForgotPassError: "¡Ups! imposible completar la recuperación de contraseña",
  ForgotPassSuccess: "Contraseña actualizada correctamente",
  ForgotPassSendMail:
    "Hemos enviado un email para completar la recuperación de contraseña",

  // Status project
  draft: "Por enviar",
  pending: "Pendiente de evaluación",
  reviewed: "Evaluado",
  approved: "Aceptado",
  rejected: "Rechazado",
  pendingOnly: "Pendiente",

  "Mail user": "Email del usuario",

  Info: "Información",
  MessageSuccess: "Acción realizada correctamente",
  MessageError: "¡Ups! Ocurrio algo inesperado, intente nuevamente",
  FilesUpload: "Archivos cargados",
  "Process complete title": "Estos son los pasos a seguir",
  "Process complete": `1. El/la director argentino / francés se registra y crea el nuevo proyecto<br><br>
  2. La contraparte también se registra, y le comunica al director que creó el proyecto el mail con el que se registró<br><br>
  3. El director que creó el proyecto le comparte acceso al proyecto a su contraparte con el botón COMPARTIR PROYECTO<br><br>
  4. A partir de este punto el proyecto queda correctamente compartido<br><br>
  5. Cada director ingresará el proyecto por su lado y podrá ver en paralelo la carga de su contraparte en el otro país<br><br>
  6. El proyecto quedará registrado definitivamente para su evaluación cuando las dos partes francesa y argentina hayan sido entregadas antes del 15 de abril de 2023, y los avales institucionales antes del 30 de abril de 2023.<br><br>
  7. Los directores argentinos y franceses podrán ingresar a su carga en la plataforma en cualquier momento mientras esté abierta la convocatoria para completar o corregir las informaciones.  <br><br>`,
  "annuncement text":
    "La convocatoria 2022 del Programa Ecos Sud Argentina se encuentra abierta hasta el 15 de abril de 2023.<br>Tenemos el placer de informarles que a partir de ahora los proyectos serán ingresados exclusivamente a través de esta plataforma desmaterializada, tanto por los equipos argentinos como franceses, para facilitar la postulación conjunta.<br>El equipo ECOS-SUD ARGENTINA espera que esta nueva modalidad responda a sus expectativas y se encuentra a su disposición para cualquier pregunta.",

  Error: "Ha ocurrido un error inesperado",
  SharedProject: {
    SUCCESS: "Tu proyecto se ha compartido exitosamente con: ",
    ANNOUNCEMENT_DISABLED: "Actualmente la Convocatoria esta inactiva",
    EXIST_USER_DIRECTOR: "Solo puedes invitar a un unico usuario como Director a este projecto",
    USER_COUNTRY_EQUAL: "Solo puedes invitar a un usuario del pais distinto al tuyo",
    USER_NOT_FOUND:
      "No hay ningún usuario registrado en la plataforma con ese correo electrónico.",
    USER_IS_SHARED: `
      <p>Ya habías compartido tu proyecto con este usuario. Si la persona a quien le compartiste el proyecto no puede ver tus cambios significa que ambos iniciaron el proceso de registro del proyecto de forma independiente. </p>
      <br />
      <p><strong>Solución: </strong> Uno de los dos integrantes debe crear una cuenta nueva en el sistema con otro correo electrónico y solicitarle a su contraparte compartir el proyecto a esa nueva dirección.</p>
    `,
    USER_IS_OWNER: `
      <p>Ha ocurrido un error.  El usuario a quien le intentas compartir el proyecto ya ha iniciado la creación de un proyecto. Sólo un usuario puede iniciar el proceso de registro de un proyecto. </p>
      <br />
      <p><strong>Solución: </strong> Uno de los dos integrantes debe crear una cuenta nueva en el sistema con otro correo electrónico y solicitarle a su contraparte compartir el proyecto a esa nueva dirección. </p>
    `,
    EXIST_PROJECT_INVITE: "Ya tienes una invitación pendiente, si deseas puedes cancelar y volver a enviarla.",
    STEP_ONE: `
      <p>El proyecto aún NO ha sido compartido. Por favor ingrese la dirección de email de su contraparte francesa y haga clic en el botón 'COMPARTIR'.</p>
      <p>El sistema enviará la invitación vía email para colaborar en el proyecto.</p>
      <p>Una vez que sea aceptada la invitación, el proyecto quedará correctamente compartido.</p>
    `,
    STEP_TWO: `
      <p>El sistema ha enviado la invitación vía email para colaborar en el proyecto.</p>
      <p>Una vez que sea aceptada la invitación el proyecto quedará correctamente compartido.</p>
      <p>Si la invitación aún NO ha sido aceptada por la contraparte puede cancelarla haciendo clic en <strong>'Cancelar invitación'</strong>.</p>
    `,
    PROJECT_SHARED_SUCCESSFULLY: `
      <p>El proyecto se encuentra correctamente compartido.</p>
    `,
    SOLICITUDE_PENDING: "Solicitud pendiente de aceptación",
  },
  SaveProject: "Guardar proyecto",
  Managers: "Responsables",
  Discipline: "Disciplina",
  Status: "Estado",
  Search: "Buscar",
  Owner: "Creador del proyecto",
  VersionEs: "Versión castellano",
  VersionFr: "Versión frances",
  ProjectFiles: "Archivos del proyecto",
  SearchListProject: "Buscar por proyecto o creador",

  // Categories proyect
  ch2_1_1: "Ciencias de la Salud",
  ch2_1_2: "Ciencias Humanas y Sociales",
  ch2_1_3: "Ciencias de la Vida",
  ch2_1_4: "Ciencias Exactas",
  ch2_1_5: "Ciencias del Universo",

  Download: "Descargar",
  Details: "Detalles",
  UpdateProject: "Actualizar proyecto",
  Enable: "Habilitar",
  Disable: "Deshabilitar",
  "Templates manager": "Gestión de plantillas",
  Templates: "Plantillas",
  "Create new template": "Crear nueva plantilla",
  "Edit template": "Editar plantilla",
  "Assign selected projects": "Asignar seleccionados a proyectos",
  "Save template": "Guardar plantilla",
  "Select area": "Seleccionar área",
  "Task title": "Titulo de la tarea",
  "Task description": "Descripción de la tarea",
  "Assign in block": "Asignar en bloque",
  "Select assign taks":
    "Seleccionar los proyectos a los que desea asignar las tareas seleccionadas",
  Users: "Usuarios",
  "Users list": "Lista de Usuarios",
  "Add user": "Añadir usuario",
  Role: "Rol",
  User: "Usuario",
  Areas: "Areas",
  State: "Estado",
  Select: "Seleccionar",
  "Full name": "Nombre y apellido",
  "Invite send": "Enviar invitación",
  "Edit user": "Editar usuario",
  Phone: "Teléfono",
  Position: "Cargo",
  Save: "Guardar",
  "Delete user": "Eliminar usuario",
  "Areas list": "Lista de áreas",
  Tasks: "Tareas",
  Progress: "Progreso",
  Director: "Director",
  "Add task": "Añadir tarea",
  "Select role": "Seleccionar rol",
  Active: "Activo",
  Inactive: "Inactivo",

  // Roles
  admin: "Administrador",
  "project-manager": "Postulante",
  "director": "Director",
  "coordinator": "Coordinador",
  "approver": "Aprobador",
  "validator": "Validador",
  
  approvers: "Aprobadores",
  validators: "Validadores",
  collaborator: "Colaborador",

  // Messages big
  MESSAGE_TEMPLATE_ASSIGN:
    "Recuerde que luego deberá asignan aprobadores y validadores para estar tareas. En caso de no hacerlo, la aprobación queda a cargo de los coordinadores de cada proyecto.",
  MESSAGE_NEW_USER:
    "Ingrese los datos del nuevo usuario y le llegará la invitación al correo electrónico proporcionado ",
  MESSAGE_ADD_USER_TO_TASKS: "Puede asignar en este paso o si no más luego",
  MESSAGE_IMCOMPLETE_SAVE_PROYECT: "El proyecto NO puede enviarse porque aún tiene campos incompletos.",
  MESSAGE_NOT_UPLOAD_AVAL_PROYECT: `
    <p>En caso de no haber descargado la plantilla del 'Aval Institucional' puede descargarla haciendo clic <a href='${LINK_2023_ES_DOWNLOAD_AVAL_INSTITUTIONAL}' target='_blank'>aqui</a></p>
    <p style='color: #ff4c51;'>Recuerde que el proyecto NO esta completo hasta que no se carga el Aval Institucional. La fecha limite para la carga del mismo es el 30 de abril de 2023</p>
  `,

  "Select project": "Seleccionar proyecto",
  "Only create": "Crear tarea solamente",
  "Create and template task": "Crear tarea y guardar como plantilla",
  "New task": "Nueva tarea",
  "Empty information": "Sin información que mostrar",
  "See tasks": "Gestión de tareas",
  "See projects": "Ver proyectos",
  "Project managers" : "Directores del proyecto",
  "Creator": "Creador",
  "Project shared": "Proyecto compartido",
  "Shared": "Compartido",
  "Not assign": "Aún no asignado",
  "Cancele invitation": "Cancelar invitación",
  Upload: "Cargar",
  See: "Ver",
  Aval: "Aval Institucional",
  'Information project': 'Información del proyecto',

  // Tasks status
  'progress': 'En Progreso',
  'pending_approval': 'Pendiente de Aprobación',
  'approval_rejected': 'Aprobación Rechazada',
  'pending_validation': 'Pendiente de Validación',
  'validation_rejected': 'Validación Rechazada',
  'completed': 'Completado',
};
