export default {
  namespaced: true,
  state: {
    hasNewUser: null,
  },
  getters: {},
  mutations: {
    SET_NEW_USER(state, value) {
      state.hasNewUser = value
    },
  },
  actions: {},
}
