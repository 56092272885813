import axios from "@axios";

export const ROLE_DIRECTOR = 'director';
export const ROLE_VALIDATOR = 'validator';
export const ROLE_APPROVER = 'approver';
export const ROLE_COORDINATOR = 'coordinator';
export const ROLE_PROJECT_MANAGER = 'project-manager';


export const LOCAL_ALL_ROLES = [ROLE_DIRECTOR, ROLE_VALIDATOR, ROLE_APPROVER, ROLE_COORDINATOR, ROLE_PROJECT_MANAGER];

const getRoles = () => {
  return axios.get("/roles");
};

export default {
  getRoles,
};
