import appConfigStoreModule from "@core/@app-config/appConfigStoreModule";
import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import templates from "./templates";
import users from "./users";
import projects from "./projects";
import areas from "./areas";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    templates,
    users,
    projects,
    areas,
  },
});
