import { canNavigate } from "@/plugins/acl/routeProtection";
import Vue from "vue";
import VueRouter from "vue-router";
import admin from "./admin/";
import users from "./users/";

Vue.use(VueRouter);

const getDataStorage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userRole = userData && userData.role ? userData.role : null;

  return { accessToken, userData, userRole };
};

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: "/",
    redirect: (to) => {
      const { accessToken, userData, userRole } = getDataStorage();

      if (accessToken && userRole && userRole?.name === "admin")
        return { name: "admin.announcements" };
      if (accessToken && userRole)
        return { name: "announcements" };

      return { name: "auth-login", query: to.query };
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/Register.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/change-password",
    name: "auth-change-password",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/project/invitation",
    name: "user.project.invitation",
    component: () => import("@/views/projects/UserProjectInvitation.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      routePublic: true,
    },
  },
  ...admin,
  ...users,
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const { accessToken, userRole } = getDataStorage();

  if(to.meta.routePublic) return next();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!accessToken)
      return next({
        name: "auth-login",
        query: { marketplace: to.query.marketplace },
      });

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // // Redirect if logged in
  if (accessToken && userRole && !to.meta.roles.includes(userRole.name)) {
    next("/");
  }

  return next();
});

export default router;
