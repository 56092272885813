export default {
  namespaced: true,
  state: {
    areaManagerSelect: null,
  },
  getters: {
    getAreaManagerSelect: ({ areaManagerSelect }) => {
      return areaManagerSelect;
    },
  },
  mutations: {
    SET_AREA_MANAGER_SELECT(state, value) {
      state.areaManagerSelect = value;
    },
  },
  actions: {
    onSetTaskAreaManagerSelect({ commit, state }, task) {
      const area = { ...state.areaManagerSelect };
      area.tasks = [task, ...(area.tasks || [])];

      commit("SET_AREA_MANAGER_SELECT", area);
    },
  },
};
