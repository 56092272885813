import { LOCAL_ALL_ROLES } from "@/services/roles";

const layout = 'users';
const roles = LOCAL_ALL_ROLES;

const projectManager = [
  {
    path: '/announcements',
    name: 'announcements',
    component: () => import('@/views/users/announcements/Announcements.vue'),
    meta: {
      layout,
      roles
    },
  },
  {
    path: '/announcements/all',
    name: 'announcements.all',
    component: () => import('@/views/users/announcements/AnnouncementsAll.vue'),
    meta: {
      layout,
      roles
    },
  },
  {
    path: '/projects/:id',
    name: 'projects.show',
    component: () => import('@/views/users/projects/ProjectShow.vue'),
    meta: {
      layout,
      roles
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/users/profile/Profile.vue'),
    meta: {
      layout,
      roles
    },
  },
]

export default projectManager
